import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Paper } from '@material-ui/core';
import * as Mui from '@material-ui/core';
import * as MuiIcons from '@material-ui/icons';
import toast from '~/services/toast';
import { MEIO_PAGAMENTO, TIPO_DOCUMENTO, API_BASE_URL } from '~/constants';
import { formatCurrency } from '~/utils/format';
import { selectorFechamento } from '~/store/modules/pdv/pdv-selectors';
import * as pdvActions from '~/store/modules/pdv/pdv-actions';
import ModalCadastroCheque from '../ModalCheque';
import PagamentoParcela from '../ModalConsultaPagamento/components/PagamentoParcela';
import ParcelaPagamentoTableRow from '../../components/ParcelaPagamentoTableRow';
import { CurrencyInputCustom } from '~/components/CustomInput';
import { Header } from './styles';
import { selectorParametroAsBoolean, selectorRotina } from '~/store/modules/auth/auth-selectors';
import { debounce } from 'lodash';

const ModalConfirmaPagamento = () => {
  const dispatch = useDispatch();
  const {
    venda,
    itemPagamento,
    tipoDocumento,
    selects,
    showModalConfirmaPagamento,
    showModalCadastroCheque
  } = useSelector(state => state.pdv);
  const { permiteFechamento, valorSaldoPagar, parcelas } = useSelector(
    selectorFechamento(itemPagamento?.parcelas ?? [])
  );

  const [valorDescontoAnterior, setValorDescontoAnterior] = useState(0);
  const [valorAcrescimoAnterior, setValorAcrescimoAnterior] = useState(0);

  const parametroPF054 = useSelector(selectorParametroAsBoolean('PF054'))
  const rotinaF140DSA = useSelector(selectorRotina('F140DSA'))

  const verificaRegraDesconto = useCallback(() => {
    if (parametroPF054) {
      const itensFiltrados = venda.itens.filter(item => {
        const produtoComRegraDesconto = item.fatRegradescontoProdutoId ?? false;
        item.edicaoManual = item.produtoEstProduto.modalRegra ? (item.produtoEstProduto.modalRegra && produtoComRegraDesconto) : item.edicaoManual
        const editado =
          item.edicaoManual && !(item.itemValorUnitario > item.produtoPrecoVenda);
        return (
          item.itemValorUnitario &&
          item.itemQuantidade &&
          !produtoComRegraDesconto &&
          !editado && !item.itemCancelado
        );
      });
      return itensFiltrados;
    } else {
      return venda.itens;
    }
  }, [parametroPF054, venda.itens]);

  const timeoutId = useRef(null);

  const changeDesconto = e => {
    const desconto = e.floatvalue;

    debouncedUpdateDesconto.cancel();
    debouncedUpdateDesconto(desconto);
  };

  const changeDescontoPercentual = e => {
    const descontoPercentual = e.floatvalue;

    debouncedUpdateDescontoPercentual.cancel();
    debouncedUpdateDescontoPercentual(descontoPercentual);
  };

  const updateDesconto = useCallback(
    desconto => {
      const itensParaDesconto = verificaRegraDesconto();

      if (itensParaDesconto.length > 0 && parametroPF054) {
        dispatch(pdvActions.atualizarDesconto(desconto, itensParaDesconto));
      } else if (!parametroPF054) {
        dispatch(pdvActions.atualizarDescontoSemParams(desconto));
      } else {
        toast.info('Nenhum item elegível para desconto.');
      }
    },
    [dispatch, verificaRegraDesconto, parametroPF054]
  );

  const debouncedUpdateDesconto = useCallback(debounce(updateDesconto, 1000), [
    updateDesconto,
  ]);

  const updateDescontoPercentual = useCallback(
    descontoPercentual => {
      const itensParaDesconto = verificaRegraDesconto();

      if (itensParaDesconto.length > 0 && parametroPF054) {
        dispatch(
          pdvActions.atualizarDescontoPercentual(descontoPercentual, itensParaDesconto)
        );
      } else if (!parametroPF054) {
        dispatch(pdvActions.atualizarDescontoPercentualSemParams(descontoPercentual));
      } else {
        toast.info('Nenhum item elegível para desconto.');
      }
    },
    [dispatch, verificaRegraDesconto, parametroPF054]
  );

  const debouncedUpdateDescontoPercentual = useCallback(
    debounce(updateDescontoPercentual, 1000),
    [updateDescontoPercentual]
  );

  useEffect(() => {
    return () => {
      debouncedUpdateDesconto.cancel();
      debouncedUpdateDescontoPercentual.cancel();
    };
  }, []);

  const changeAcrescimo = e => {
    const acrescimo = e.floatvalue;
    dispatch(pdvActions.atualizarAcrescimo(acrescimo));
  };

  const changeNumeroDocumento = (documentoNumero, entrada) => {
    const parcelasCartao = entrada
      ? [parcelas?.find(x => x.finMeiopagamentoId === itemPagamento?.meioPagamentoEntrada?.id)]
      : parcelas?.filter(x => x.finMeiopagamentoId === itemPagamento?.meioPagamento?.id);

    for (const parcela of parcelasCartao) {
      dispatch(pdvActions.atualizarParcelaFechamento({ ...parcela, documentoNumero }));
    }
    dispatch(pdvActions.atualizarNumeroDocumentoCartao(documentoNumero, entrada));
  };

  const changeBandeiraCartao = (fatCartaobandeira, entrada) => {
    const parcelasCartao = entrada
      ? [parcelas?.find(x => x.finMeiopagamentoId === itemPagamento?.meioPagamentoEntrada?.id)]
      : parcelas?.filter(x => x.finMeiopagamentoId === itemPagamento?.meioPagamento?.id);

    for (const parcela of parcelasCartao) {
      dispatch(pdvActions.atualizarParcelaFechamento({ ...parcela, fatCartaobandeira }));
    }
    dispatch(pdvActions.atualizarBandeiraCartao(fatCartaobandeira, entrada));
  };

  const handleClose = () => {
    dispatch(pdvActions.setShowModalConfirmaPagamento({ show: false }));
  };

  const handleSubmit = () => {

    if(itemPagamento?.meioPagamento?.nfePagamento === MEIO_PAGAMENTO.CHEQUE &&
      tipoDocumento === TIPO_DOCUMENTO.DOCUMENTOSAIDA && itemPagamento.parcelas.length > 0) {
        let parcelaSemCheque = false;
        parcelaSemCheque = itemPagamento.parcelas.some(parcela => {
          if(!parcela.cheque) return true;
        })
        if(parcelaSemCheque) {
          toast.warning('Informe os dados do cheque da parcela!');
          return;
        }
      }

      
    if (
      tipoDocumento === TIPO_DOCUMENTO.DOCUMENTOSAIDA &&
      (itemPagamento?.meioPagamento?.nfePagamento === MEIO_PAGAMENTO.CARTAO_CREDITO ||
        itemPagamento?.meioPagamento?.nfePagamento === MEIO_PAGAMENTO.CARTAO_DEBITO ||
        itemPagamento?.meioPagamentoEntrada?.nfePagamento === MEIO_PAGAMENTO.CARTAO_CREDITO ||
        itemPagamento?.meioPagamentoEntrada?.nfePagamento === MEIO_PAGAMENTO.CARTAO_DEBITO)
    ) {
      if (
        itemPagamento?.meioPagamento &&
        itemPagamento?.meioPagamento?.tef &&
        itemPagamento?.meioPagamento?.tefModalidade === null &&
        itemPagamento?.meioPagamento?.documentoNumero === ''
      ) {
        toast.warning('Informar o número do documento (POS)');
        return;
      }

      if (
        itemPagamento?.meioPagamento &&
        itemPagamento?.meioPagamento?.tef &&
        !itemPagamento?.meioPagamento?.fatCartaobandeira
      ) {
        toast.warning('Informar bandeira para pagamento (parcelas) com cartão crédito/débito');
        return;
      }

      if (
        itemPagamento?.meioPagamentoEntrada &&
        itemPagamento?.meioPagamentoEntrada?.tef &&
        itemPagamento?.meioPagamentoEntrada?.tefModalidade === null &&
        itemPagamento?.meioPagamentoEntrada?.documentoNumero === ''
      ) {
        toast.warning('Informar o número do documento (POS)');
        return;
      }

      if (
        itemPagamento?.meioPagamentoEntrada &&
        itemPagamento?.meioPagamentoEntrada?.tef &&
        !itemPagamento?.meioPagamentoEntrada?.fatCartaobandeira
      ) {
        toast.warning('Informar bandeira para pagamento (entrada) com cartão crédito/débito');
        return;
      }
    }
    dispatch(pdvActions.setShowModalConfirmaPagamento({ show: false }));
    // dispatch(pdvActions.fecharVenda());
    dispatch(pdvActions.adicionarPagamento());
  };

  useEffect(() => {
    return () => {
      if (timeoutId.current) {
        clearTimeout(timeoutId.current);
      }
    };
  }, []);

  useEffect(() => {
    dispatch(pdvActions.iniciaPagamento());

    setValorDescontoAnterior(venda?.valorDesconto ?? 0);
    setValorAcrescimoAnterior(venda?.valorAcrescimo ?? 0);
  }, []);

  return (
    <>
      <Mui.Dialog
        open={showModalConfirmaPagamento}
        onKeyDown={key => {
          if (key.code === 'Escape') {
            handleClose();
          }
        }}
        PaperComponent={Paper}
        maxWidth={'md'}
        style={{zIndex: 99}}
      >
        <Mui.DialogTitle>
          <Mui.AppBar color="primary" position="static">
            <Mui.Toolbar>
              <Mui.Box>
                <Mui.Typography variant="h5" style={{ textTransform: 'upperCase' }}>
                  FECHAMENTO VENDA
                </Mui.Typography>
              </Mui.Box>
              <Mui.Box flexGrow={1} />

              <Mui.IconButton color="inherit" onClick={handleClose} aria-label="close">
                <MuiIcons.Close />
              </Mui.IconButton>
            </Mui.Toolbar>
          </Mui.AppBar>
          <Header>
            <Mui.Box ml={1}>
              <Mui.Typography variant="caption">Forma pagamento</Mui.Typography>
              <Mui.Typography variant="h5">
                {itemPagamento?.meioPagamento?.descricao} (
                {itemPagamento?.condicaoPagamento?.descricao})
              </Mui.Typography>
            </Mui.Box>
            <Mui.Box textAlign="right">
              <Mui.Typography variant="caption" align="right">
                Saldo a pagar
              </Mui.Typography>
              <Mui.Typography
                variant="h3"
                align="right"
                style={{ textTransform: 'uppercase', fontWeight: 600 }}
              >
                {formatCurrency(venda?.valorTotal)}
              </Mui.Typography>
            </Mui.Box>
          </Header>
        </Mui.DialogTitle>
        <Mui.DialogContent dividers style={{ maxWidth: 640 }}>
          <Mui.Box p={2} mb={2}>
            <Mui.Box mb={2}>
              <Mui.Paper>
                <Mui.Box p={2}>
                  <Mui.Grid container spacing={2}>
                    <Mui.Grid item md={4}>
                      <Mui.Typography align="left" variant="caption">
                        Desconto (R$)
                      </Mui.Typography>
                      <Mui.TextField
                        value={venda?.valorDesconto ?? 0}
                        onChange={changeDesconto}
                        variant="outlined"
                        fullWidth
                        disabled={(tipoDocumento === TIPO_DOCUMENTO.DOCUMENTOSAIDA && !rotinaF140DSA)}
                        InputProps={{
                          startAdornment: (
                            <Mui.InputAdornment position="start">R$</Mui.InputAdornment>
                          ),
                          inputComponent: CurrencyInputCustom,
                          style: { height: 54 }
                        }}
                      />
                    </Mui.Grid>
                    <Mui.Grid item md={2}>
                      <Mui.Typography align="left" variant="caption">
                        Desc.(%)
                      </Mui.Typography>
                      <Mui.TextField
                        value={venda?.percentualDesconto ?? 0}
                        onChange={changeDescontoPercentual}
                        variant="outlined"
                        fullWidth
                        disabled={(tipoDocumento === TIPO_DOCUMENTO.DOCUMENTOSAIDA && !rotinaF140DSA)}
                        InputProps={{
                          inputComponent: CurrencyInputCustom,
                          style: {
                            height: 54
                          }
                        }}
                      />
                    </Mui.Grid>
                    <Mui.Grid item md={4}>
                      <Mui.Typography align="left" variant="caption">
                        Acréscimo (R$)
                      </Mui.Typography>
                      <Mui.TextField
                        value={venda?.valorAcrescimo ?? 0}
                        onChange={changeAcrescimo}
                        variant="outlined"
                        fullWidth
                        disabled={tipoDocumento === TIPO_DOCUMENTO.DOCUMENTOSAIDA ? !rotinaF140DSA : ''}
                        InputProps={{
                          startAdornment: (
                            <Mui.InputAdornment position="start">R$</Mui.InputAdornment>
                          ),
                          inputComponent: CurrencyInputCustom,
                          style: {
                            height: 54
                          }
                        }}
                      />
                    </Mui.Grid>
                  </Mui.Grid>
                </Mui.Box>
              </Mui.Paper>
            </Mui.Box>

            {itemPagamento?.meioPagamento?.nfePagamento === MEIO_PAGAMENTO.DINHEIRO && (
              <Mui.Paper>
                <Mui.Box p={2}>
                  <Mui.Typography variant="caption" align="left">
                    {tipoDocumento === TIPO_DOCUMENTO.DOCUMENTOSAIDA
                      ? 'Valor Recebido (R$)'
                      : 'Valor total (R$)'}
                  </Mui.Typography>
                  <Mui.TextField
                    variant="outlined"
                    fullWidth
                    value={
                      tipoDocumento === TIPO_DOCUMENTO.DOCUMENTOSAIDA
                        ? itemPagamento?.valorRecebido
                        : venda.valorTotal
                    }
                    disabled={tipoDocumento !== TIPO_DOCUMENTO.DOCUMENTOSAIDA}
                    onChange={({ floatvalue }) => {
                      dispatch(pdvActions.atualizarValorRecebido(floatvalue));
                    }}
                    InputProps={{
                      startAdornment: <Mui.InputAdornment position="start">R$</Mui.InputAdornment>,
                      inputComponent: CurrencyInputCustom,
                      style: {
                        height: 54
                      }
                    }}
                  />
                </Mui.Box>
                {tipoDocumento === TIPO_DOCUMENTO.DOCUMENTOSAIDA && (
                  <>
                    <Mui.Box p={2} display="flex" flexDirection="column" alignItems="center">
                      <Mui.Typography variant="caption">Troco (R$)</Mui.Typography>

                      <Mui.Typography variant="h1">
                        {formatCurrency(itemPagamento?.valorTroco)}
                      </Mui.Typography>
                    </Mui.Box>
                  </>
                )}
              </Mui.Paper>
            )}
            <Mui.Paper>
              {itemPagamento?.condicaoPagamento?.entradaExigida && (
                <Mui.Box p={2}>
                  <Mui.Typography variant="caption">Meio de pagamento da entrada</Mui.Typography>

                  <Mui.Grid item md={12} style={{ marginBottom: 16 }}>
                    {itemPagamento?.condicaoPagamento?.meiosPagamentoEntrada?.map(
                      (meioPagamento, index) => {
                        const entradaSelecionada =
                          itemPagamento?.meioPagamentoEntrada?.id === meioPagamento?.id;

                        return (
                          <>
                            <Mui.Button
                              key={`index-${index}`}
                              variant="contained"
                              color={entradaSelecionada ? 'primary' : 'default'}
                              style={{ margin: 4 }}
                              onClick={() =>
                                dispatch(pdvActions.selecionarMeiopagamentoEntrada(meioPagamento))
                              }
                            >
                              {meioPagamento?.descricao}
                            </Mui.Button>
                          </>
                        );
                      }
                    )}
                  </Mui.Grid>

                  <Mui.Grid container spacing={2}>
                    <Mui.Grid item md={6}>
                      <Mui.Box flex flexDirection="row">
                        <Mui.Box mb={2}>
                          <Mui.Typography variant="caption" align="left">
                            Valor entrada (R$)
                          </Mui.Typography>
                          <Mui.TextField
                            variant="outlined"
                            fullWidth
                            value={itemPagamento?.valorEntrada}
                            onChange={({ floatvalue }) => {
                              dispatch(pdvActions.atualizarValorEntrada(floatvalue));
                            }}
                            InputProps={{
                              inputComponent: CurrencyInputCustom,
                              style: {
                                height: 54
                              }
                            }}
                          />
                        </Mui.Box>
                      </Mui.Box>
                    </Mui.Grid>
                  </Mui.Grid>

                  {tipoDocumento === TIPO_DOCUMENTO.DOCUMENTOSAIDA &&
                    itemPagamento?.meioPagamentoEntrada?.tef && (
                      <Mui.Grid container spacing={2}>
                        {itemPagamento?.meioPagamentoEntrada?.tefModalidade === null && (
                          <Mui.Grid item md={6}>
                            <Mui.Typography align="left" variant="caption">
                              Número do Documento (entrada)
                            </Mui.Typography>
                            <Mui.TextField
                              value={itemPagamento?.meioPagamentoEntrada?.documentoNumero ?? ''}
                              onChange={e => changeNumeroDocumento(e.target.value, true)}
                              variant="outlined"
                              fullWidth
                            />
                          </Mui.Grid>
                        )}

                        <Mui.Grid
                          item
                          md={itemPagamento?.meioPagamentoEntrada?.tefModalidade === null ? 6 : 12}
                        >
                          <Mui.Typography align="left" variant="caption">
                            Bandeira do cartão (entrada)
                          </Mui.Typography>
                          <Mui.Select
                            variant="outlined"
                            value={itemPagamento?.meioPagamentoEntrada?.fatCartaobandeira ?? null}
                            onChange={e => changeBandeiraCartao(e.target.value, true)}
                            fullWidth
                          >
                            {selects?.bandeiras
                              ?.filter(x =>
                                x.operacao ==
                                (itemPagamento?.meioPagamentoEntrada?.nfePagamento ===
                                  MEIO_PAGAMENTO.CARTAO_CREDITO)
                                  ? 0
                                  : 1
                              )
                              ?.map((x, index) => (
                                <Mui.MenuItem key={index} value={x}>
                                  {x.descricao}
                                </Mui.MenuItem>
                              ))}
                          </Mui.Select>
                        </Mui.Grid>
                      </Mui.Grid>
                    )}
                </Mui.Box>
              )}

              <Mui.Divider />
              {(itemPagamento?.meioPagamento?.nfePagamento === MEIO_PAGAMENTO.CARTAO_CREDITO ||
                itemPagamento?.meioPagamento?.nfePagamento === MEIO_PAGAMENTO.CARTAO_DEBITO) && (
                <>
                  {tipoDocumento === TIPO_DOCUMENTO.DOCUMENTOSAIDA &&
                    itemPagamento?.meioPagamento?.tef && (
                      <Mui.Box p={2}>
                        <Mui.Grid container spacing={2}>
                          {itemPagamento?.meioPagamento?.tefModalidade === null && (
                            <Mui.Grid item md={6}>
                              <Mui.Typography align="left" variant="caption">
                                Número de Autorização (parcelas)
                              </Mui.Typography>
                              <Mui.TextField
                                value={itemPagamento?.meioPagamento?.cartaoAutorizacao ?? ''}
                                onChange={e => changeNumeroDocumento(e.target.value, false)}
                                variant="outlined"
                                fullWidth
                              />
                            </Mui.Grid>
                          )}

                          <Mui.Grid item md={itemPagamento?.meioPagamento?.tefModalidade === null ? 6 : 12}>
                            <Mui.Typography align="left" variant="caption">
                              Bandeira do cartão (parcelas)
                            </Mui.Typography>
                            <Mui.Select
                              variant="outlined"
                              value={itemPagamento?.meioPagamento?.fatCartaobandeira ?? null}
                              onChange={e => changeBandeiraCartao(e.target.value, false)}
                              fullWidth
                            >
                              {selects?.bandeiras
                                ?.filter(x =>
                                  x.operacao ==
                                  (itemPagamento?.meioPagamento?.nfePagamento ===
                                    MEIO_PAGAMENTO.CARTAO_CREDITO)
                                    ? 0
                                    : 1
                                  )
                                  ?.map((x, index) => (
                                  <Mui.MenuItem key={index} value={x}>
                                    {x.descricao}
                                  </Mui.MenuItem>
                                ))
                              }
                            </Mui.Select>
                          </Mui.Grid>
                        </Mui.Grid>
                      </Mui.Box>
                    )}

                  <Mui.Divider />
                  <Mui.Box p={2} display="flex" flexDirection="column" alignItems="center">
                    <Mui.Typography variant="caption">Valor recebido (R$)</Mui.Typography>
                    <Mui.Typography variant="h1">
                      {formatCurrency(itemPagamento?.valorRecebido)}
                    </Mui.Typography>
                    <PagamentoParcela show={true} selected={true} />
                  </Mui.Box>
                </>
              )}
            </Mui.Paper>
            {parcelas?.length > 0 &&
              !(
                itemPagamento?.meioPagamento?.nfePagamento === MEIO_PAGAMENTO.DINHEIRO ||
                itemPagamento?.meioPagamento?.nfePagamento === MEIO_PAGAMENTO.CARTAO_CREDITO ||
                itemPagamento?.meioPagamento?.nfePagamento === MEIO_PAGAMENTO.CARTAO_DEBITO
              ) && (
                <Mui.TableContainer component={Paper}>
                  <Mui.Table aria-label="spanning table">
                    <Mui.TableHead>
                      <Mui.TableRow>
                        <Mui.TableCell align="left">Parcela</Mui.TableCell>
                        <Mui.TableCell align="center">Data</Mui.TableCell>
                        <Mui.TableCell align="right">Valor</Mui.TableCell>
                        <Mui.TableCell align="center">#</Mui.TableCell>
                      </Mui.TableRow>
                    </Mui.TableHead>
                    <Mui.TableBody>
                      {parcelas?.map((parcela, parcelaIndex) => (
                        <ParcelaPagamentoTableRow key={parcelaIndex} parcela={parcela} />
                      ))}
                    </Mui.TableBody>
                  </Mui.Table>
                </Mui.TableContainer>
              )}
          </Mui.Box>
        </Mui.DialogContent>
        <Mui.DialogActions>
          {!permiteFechamento ||
          (valorSaldoPagar !== 0 && tipoDocumento !== TIPO_DOCUMENTO.ORCAMENTO) ? (
            <Mui.Button
              size="large"
              onClick={() => {
                dispatch(pdvActions.iniciaPagamento());
              }}
              variant="contained"
            >
              <MuiIcons.Refresh /> <span style={{ paddingLeft: 8 }}>CALCULAR PARCELAS</span>
            </Mui.Button>
          ) : (
            <Mui.Button
              size="large"
              onClick={handleSubmit}
              variant="contained"
              color="primary"
              disabled={
                !permiteFechamento ||
                (valorSaldoPagar !== 0 && tipoDocumento !== TIPO_DOCUMENTO.ORCAMENTO)
              }
            >
              <MuiIcons.CheckCircle /> <span style={{ paddingLeft: 8 }}>CONFIRMAR PAGAMENTO</span>
            </Mui.Button>
          )}

          <Mui.Button startIcon={<MuiIcons.Close />} size="large" onClick={handleClose}>
            CANCELAR (ESC)
          </Mui.Button>
        </Mui.DialogActions>
      </Mui.Dialog>

      {showModalCadastroCheque?.show && (
        <ModalCadastroCheque
          callback={data => {
            dispatch(pdvActions.setShowModalCadastroCheque({ show: false }));
            dispatch(pdvActions.adicionarChequeParcela(itemEdicao.uuid, data));
          }}
        />
      )}
    </>
  );
};

export default ModalConfirmaPagamento;
