import React, { useRef, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from '@material-ui/core';
import currency from 'currency.js';
import Skeleton from '@material-ui/lab/Skeleton';
import { Alert as MuiAlert } from '@material-ui/lab';
import * as Mui from '@material-ui/core';
import * as MuiIcons from '@material-ui/icons';

import { formatCurrency } from '~/utils/format';
import { SET_FOCUS_CONSULTA_PRODUTO, SET_FOCUS_QUANTIDADE } from '~/store/modules/pdv/pdv-constants';
import { TIPO_DOCUMENTO } from '~/constants';
import * as pdvActions from '~/store/modules/pdv/pdv-actions';

import { CurrencyInputCustom } from '~/components/CustomInput';
import ModalCondicaoPagamentoProduto from '~/pages/TelaVenda/modal/ModalCondicaoPagamentoProduto';
import ModalProdutoSimilar from '~/pages/TelaVenda/modal/ModalProdutoSimilar';
import ModalVendaCasada from '~/pages/TelaVenda/modal/ModalVendaCasada';
import NotFound from '~/assets/not-found.png';
import { CustomNumberInput } from '~/components/CustomNumberInput';
import ModalEnderecos from '~/pages/TelaVenda/modal/ModalEnderecos';
import { selectorRotina } from '~/store/modules/auth/auth-selectors';
import { Tooltip } from '@material-ui/core';
import toast from '~/services/toast';
import { selectorParametroAsBoolean } from '~/store/modules/auth/auth-selectors';

const ConsultaProduto = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const quantidadeRef = useRef();

  const permissoes = useSelector(state => state?.auth?.permissoes ?? []);
  const { loadingProduto, itemVenda, venda, tipoDocumento, setFocusInputQuantidade } = useSelector(
    state => state.pdv
  );
  const [showModalCondicaoPagamento, setShowModalCondicaoPagamento] = useState(false);
  const [showModalProdutoSimilar, setShowModalProdutoSimilar] = useState(false);
  const [showModalEnderecos, setShowModalEnderecos] = useState(false);
  const [showModalVendaCasada, setShowModalVendaCasada] = useState(false);
  const [editarValorUnitario, setEditarValorUnitario] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [valorOriginalProduto, setValorOriginalProduto] = useState(
    itemVenda?.produtoEstProduto?.estProdutoImposto[0]?.precoVenda
  );

  const bloqueiaDesconto = useSelector(selectorRotina('F060FAT'));
  const parametroPF054 = useSelector(selectorParametroAsBoolean('PF054'))
  const rotinaF140DSA = useSelector(selectorRotina('F140DSA'))

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const changeUnidadeComercial = e => {
    const unidade = e.target.value;
    dispatch(pdvActions.alterarUnidadeComercial(unidade));
  };

  const changeQuantidade = quantidade => {
    const produto = itemVenda?.produtoEstProduto;
    const embalagemMultiplo = produto?.embalagemMultiplo;
    const fracionavel = produto?.fracionavel;

    if (embalagemMultiplo === true) {
      return handleEmbalagemMultiplo(quantidade);
    } else if (fracionavel) {
      return handleFracionavel(quantidade);
    } else {
      return handleDefault(quantidade);
    }
  };

  const montarItemVenda = itemVenda => {
    const itemQuantidade = Number(itemVenda.itemQuantidade);

    const itemValorUnitario = Number(currency(itemVenda?.itemValorUnitario, { precision: 2 }));

    const itemValorTotal = Number(
      currency(itemValorUnitario * itemQuantidade, { precision: 2 })
        .add(itemVenda?.itemValorAcrescimo ?? 0)
        .add(itemVenda?.impostoIcmsSt ?? 0)
        .add(itemVenda?.impostoIpi ?? 0)
        .subtract(itemVenda?.itemValorDesconto ?? 0)
    );

    const quantidadexembalagem = Number(
      currency(itemVenda.produtoQuantidadeEmbalagem * itemVenda.itemQuantidade, {
        precision: 2
      })
    );
    return {
      ...itemVenda,
      itemQuantidade,
      quantidadexembalagem,
      itemValorUnitario,
      itemValorTotal
    };
  };

  const handleEmbalagemMultiplo = e => {
    const produto = itemVenda?.produtoEstProduto;
    const quantidadeEmbalagem = produto?.embalagemQuantidade;
    const quantidadeRefValue = quantidadeRef?.current?.valueAsNumber;

    if (quantidadeEmbalagem > 0 && quantidadeRefValue !== 0) {
      const resto = quantidadeRefValue % quantidadeEmbalagem
      let quantidadeAjustada
      if (resto >= quantidadeEmbalagem / 2) {
        quantidadeAjustada = quantidadeRefValue + (quantidadeEmbalagem - resto);
      }	else {
        quantidadeAjustada = quantidadeRefValue - resto
      }

      quantidadeAjustada = parseFloat(quantidadeAjustada.toFixed(2));

      updateQuantidade(quantidadeAjustada.toFixed(2));
      showToastIfDifferent(quantidadeRefValue, quantidadeAjustada.toFixed(2));

      if (quantidadeAjustada) {
        return dispatch(pdvActions.atualizarQuantidade(itemVenda, parseFloat(quantidadeAjustada.toFixed(2))));
      }
    }
  };

  const handleFracionavel = quantidade => {
    const valorFormatado = handleDefault(quantidade);
    return dispatch(
      pdvActions.atualizarQuantidade(itemVenda, parseFloat(valorFormatado.itemQuantidade))
    );
  };

  const handleDefault = quantidade => {
    const regex = /^[0-9\b]+$/;

    if (quantidade === '' || regex.test(quantidade)) {
      dispatch(pdvActions.atualizarQuantidade(itemVenda, quantidade));
    }
    const valorFormatado = quantidade.replace('.', '').replace(',', '.');
    return {
      ...itemVenda,
      itemQuantidade: valorFormatado
    };
  };

  const updateQuantidade = quantidade => {
    const produto = itemVenda?.produtoEstProduto;
    const quantidadeCalculada = quantidade / produto?.embalagemQuantidade;

    quantidadeRef.current.value = quantidade;
    itemVenda.itemQuantidade = quantidade;
    itemVenda.quantidadexembalagem = quantidadeCalculada;
  };

  const showToastIfDifferent = (originalQuantidade, novaQuantidade) => {
    if (originalQuantidade !== novaQuantidade) {
      const produto = itemVenda?.produtoEstProduto;
      toast.produtoMulti(
        `<b>${produto?.codigo} - ${produto?.descricao}</b> é múltiplo de embalagem ${produto?.embalagemQuantidade}, 
        e a quantidade vendida foi alterada de ${originalQuantidade} para ${novaQuantidade}`
      );
    }
  };

  const changeValorUnitario = e => {
    const precoVenda = e.floatvalue;

    itemVenda.itemValorPrecoVendaOriginal = valorOriginalProduto
    if (precoVenda < valorOriginalProduto) {
      itemVenda.edicaoManual = true;

      itemVenda.itemValorDesconto = 0;
      itemVenda.itemPercentualDesconto = 0;
    } else if (precoVenda > valorOriginalProduto) {
      itemVenda.edicaoManual = true;
    } else {
      itemVenda.edicaoManual = false;
    }
    // itemVenda.edicaoManual = true;
    dispatch(pdvActions.atualizarPrecoVendaProduto(itemVenda, precoVenda));
  };

  const changeDesconto = e => {
    const desconto = e.floatvalue;
    dispatch(pdvActions.atualizarDescontoItem(itemVenda, desconto));
  };

  const changeDescontoPercentual = e => {
    const desconto = e.floatvalue;
    dispatch(pdvActions.atualizarDescontoItemPercentual(itemVenda, desconto));
  };

  const changeAcrescimo = e => {
    const acrescimo = e.floatvalue;
    dispatch(pdvActions.atualizarAcrescimoItem(itemVenda, acrescimo));
  };

  const keyDownQuantidade = ev => {
    if (ev.key === 'Enter') {
      ev.preventDefault();
      const venda = changeQuantidade(ev.target.value);
      let vendaFinal = venda;
      if (venda.itemVenda) vendaFinal = venda.itemVenda;
      const itemMontado = montarItemVenda(vendaFinal);
      handleAdicionarProduto(itemMontado);
    }
  };

  const handleAdicionarProduto = async venda => {
    const vendaFinal = venda?.itemQuantidade ? venda : itemVenda;
    const editado = venda?.itens?.find(x => x.uuid === vendaFinal?.uuid);
    if (editado) vendaFinal.editado = true;
    if (venda?.parcelas?.length > 0) {
      const { isConfirmed } = await toast.confirm(
        'Deseja alterar a forma de efetuar os pagamentos?',
        'Ao adicionar um produto, irá remover os pagamentos anteriores',
        {
          confirmButtonText: 'Confirmar',
          cancelButtonText: 'Cancelar'
        }
      );

      if (isConfirmed) {
        dispatch(pdvActions.limparPagamentos());
        dispatch(pdvActions.selecionarMeioPagamento(null));
        dispatch(pdvActions.selecionarCondicaoPagamento(null));
        dispatch(pdvActions.setMultiplosPagamentos(false));
        dispatch(
          pdvActions.adicionarItem(vendaFinal, () => {
            dispatch({ type: SET_FOCUS_QUANTIDADE, focus: false });
            dispatch({ type: SET_FOCUS_CONSULTA_PRODUTO, focus: true });
          })
        );
      }
    } else {
      dispatch(
        pdvActions.adicionarItem(vendaFinal, () => {
          dispatch({ type: SET_FOCUS_QUANTIDADE, focus: false });
          dispatch({ type: SET_FOCUS_CONSULTA_PRODUTO, focus: true });
        })
      );
    }
  };

  useEffect(() => {
    if (setFocusInputQuantidade) {
      quantidadeRef?.current?.focus();
      quantidadeRef?.current?.select();
      dispatch({ type: SET_FOCUS_QUANTIDADE, focus: false });
    }
  }, [setFocusInputQuantidade]);

  useEffect(() => {
    const _permissao = permissoes.find(
      x => 
        (tipoDocumento === TIPO_DOCUMENTO.PEDIDO && x.pctRotinaRotina === 'F120PED') ||
        (tipoDocumento === TIPO_DOCUMENTO.ORCAMENTO && x.pctRotinaRotina === 'F080ORC') ||
        (tipoDocumento === TIPO_DOCUMENTO.DOCUMENTOSAIDA && x.pctRotinaRotina === 'F120DSA')
    );
    const acesso = _permissao?.acesso ?? false;
    if (acesso) {
      setEditarValorUnitario(true);
    }
  }, [permissoes]);

  let produtoModalregra = itemVenda?.produtoEstProduto?.modalRegra || itemVenda?.modalRegra

  const isDisabledDesconto = () => {
    const editado = itemVenda.edicaoManual && !(itemVenda.itemValorUnitario > itemVenda.produtoPrecoVenda)
    const produtoModal = (itemVenda?.produtoEstProduto?.modalRegra || itemVenda?.modalRegra) || false

    return (
      !itemVenda?.produtoEstProduto ||
      loadingProduto ||
      bloqueiaDesconto || (parametroPF054 && editado) || (parametroPF054 && produtoModal) ||
      (tipoDocumento === TIPO_DOCUMENTO.DOCUMENTOSAIDA ? !rotinaF140DSA : false)
    );
  };
  
  const isDisabledQuantidade = () => {
    const produtoModal = itemVenda?.produtoEstProduto?.modalRegra || itemVenda?.modalRegra

    return !itemVenda?.produtoEstProduto || loadingProduto || (parametroPF054 && produtoModal)
  }

  return (
    <>
      <Mui.Grid item xs={12} style={{ boxShadow: theme.shadows[3], marginBottom: 32 }}>
        <Mui.Grid container item spacing={4} style={{ padding: 16 }}>
          <Mui.Grid item lg={12} md={12} xs={12}>
            <Mui.Grid container item>
              <Mui.Grid container item md={10}>
                <Mui.Grid item md={12}>
                  {loadingProduto ? (
                    <Skeleton variant="text" width={210} height={18} />
                  ) : (
                    <Mui.Typography variant="body2">{itemVenda?.produtoCodigo}</Mui.Typography>
                  )}
                </Mui.Grid>
                <Mui.Grid item md={12}>
                  {loadingProduto ? (
                    <Skeleton variant="text" height={36} />
                  ) : (
                    <Mui.Typography
                      variant="h3"
                      noWrap
                      style={{ textTransform: 'uppercase', fontWeight: 600 }}
                    >
                      {itemVenda?.produtoDescricao ?? 'INFORMAR PRODUTO'}
                    </Mui.Typography>
                  )}
                </Mui.Grid>
                <Mui.Grid container item md={12} justify="space-between" spacing={2}>
                  <Mui.Grid item md={3}>
                    {loadingProduto ? (
                      <Skeleton variant="text" />
                    ) : (
                      <Mui.Typography variant="caption">
                        Cód.auxiliar:
                        <b> {itemVenda?.produtoCodigoAuxiliar ?? 0}</b>
                      </Mui.Typography>
                    )}
                  </Mui.Grid>
                  <Mui.Grid item md={3}>
                    {loadingProduto ? (
                      <Skeleton variant="text" />
                    ) : (
                      <Mui.Typography variant="caption">
                        Cód.barra:
                        <b> {itemVenda?.produtoEan ?? 0}</b>
                      </Mui.Typography>
                    )}
                  </Mui.Grid>
                  <Mui.Grid item md={3}>
                    {loadingProduto ? (
                      <Skeleton variant="text" />
                    ) : (
                      <Mui.Typography variant="caption">
                        Cód.fabricante:
                        <b> {itemVenda?.produtoCodigoFabricante ?? 0}</b>
                      </Mui.Typography>
                    )}
                  </Mui.Grid>
                  <Mui.Grid item md={3}>
                    {loadingProduto ? (
                      <Skeleton variant="text" />
                    ) : (
                      <Mui.Typography variant="caption" align="center">
                        Unidade:
                        <b> {itemVenda?.produtoUnidadeComercial} </b>
                      </Mui.Typography>
                    )}
                  </Mui.Grid>
                </Mui.Grid>
              </Mui.Grid>
              <Mui.Grid
                container
                item
                md={2}
                direction="column"
                alignContent="flex-end"
                justify="center"
              >
                <Mui.Typography variant="caption" align="right">
                  Valor Unitário:
                </Mui.Typography>
                {loadingProduto ? (
                  <Skeleton variant="text" width={80} height={24} />
                ) : (
                  <>
                    {editarValorUnitario ? (
                      <Mui.TextField
                        id="itemValorUnitario"
                        variant="outlined"
                        fullWidth
                        value={itemVenda?.itemValorUnitario ?? 0}
                        onChange={changeValorUnitario}
                        disabled={!itemVenda?.produtoEstProduto || produtoModalregra || loadingProduto}
                        InputProps={{
                          inputComponent: CurrencyInputCustom,
                          style: {
                            height: 44
                          }
                        }}
                      />
                    ) : (
                      <Mui.Typography variant="h4" align="right">
                        {formatCurrency(itemVenda?.itemValorUnitario)}
                      </Mui.Typography>
                    )}
                  </>
                )}
              </Mui.Grid>
            </Mui.Grid>

            <Mui.Divider orientation="horizontal" style={{ margin: '16px 0' }} />

            <Mui.Grid container item spacing={2}>
              <Mui.Grid item md={2} xs={12}>
                <Mui.Paper style={{ padding: 8, backgroundColor: '#FFF' }}>
                  {loadingProduto ? (
                    <Skeleton variant="rect" width="100%" height={120} />
                  ) : (
                    <img
                      src={itemVenda?.imageUrl ? itemVenda?.imageUrl : NotFound}
                      alt=""
                      width="100%"
                    />
                  )}
                </Mui.Paper>
              </Mui.Grid>
              <Mui.Grid container item md={10}>
                {itemVenda?.produtoEstProduto?.dicaAplicacao && (
                  <Mui.Grid container item>
                    <MuiAlert severity="info" my={3} style={{ width: '100%', marginBottom: 8 }}>
                      <Mui.Typography variant="caption">Dica de Aplicação:</Mui.Typography>
                      <Mui.Typography>
                        {itemVenda?.produtoEstProduto?.dicaAplicacao ?? ''}
                      </Mui.Typography>
                    </MuiAlert>
                  </Mui.Grid>
                )}

                <Mui.Grid container item md={12} spacing={1}>
                  <Mui.Grid item md={2}>
                    <Mui.Typography variant="caption" align="left">
                      Unid. venda
                    </Mui.Typography>

                    <Mui.Select
                      id="produtoUnidadeComercial"
                      variant="outlined"
                      fullWidth
                      disabled={!itemVenda?.produtoEstProduto || loadingProduto}
                      style={{ height: 44 }}
                      defaultValue={'UN'}
                      value={itemVenda?.produtoUnidadeComercial ?? ''}
                      onChange={changeUnidadeComercial}
                    >
                      {itemVenda?.produtoEstProduto?.listaUnidades?.map(item => (
                        <Mui.MenuItem key={item?.valor} value={item?.valor}>
                          {item?.valor}
                        </Mui.MenuItem>
                      ))}
                    </Mui.Select>
                  </Mui.Grid>
                  <Mui.Grid item md={3}>
                    <Mui.Typography variant="caption">Quantidade (*)</Mui.Typography>

                    {itemVenda?.produtoEstProduto?.fracionavel && !itemVenda?.produtoEstProduto.embalagemMultiplo ? (
                      <Mui.TextField
                        id="itemQuantidade"
                        inputRef={quantidadeRef}
                        type="numeric"
                        variant="outlined"
                        fullWidth
                        required
                        autoFocus
                        disabled={isDisabledQuantidade() /*|| !itemVenda?.produtoEstProduto || loadingProduto*/}
                        onChange={e => { handleFracionavel(e.target.value) }}
                        onBlur={e => changeQuantidade(e.target.value)}
                        value={itemVenda?.itemQuantidade ?? 1}
                        onKeyPress={ev => keyDownQuantidade(ev)}
                        InputProps={{
                          style: {
                            height: 44
                          },
                          inputComponent: CustomNumberInput
                        }}
                      />
                    ) : (
                      <Mui.TextField
                        id="itemQuantidade"
                        inputRef={quantidadeRef}
                        type="number"
                        variant="outlined"
                        fullWidth
                        autoFocus
                        InputProps={{
                          style: {
                            height: 44
                          }
                        }}
                        disabled={isDisabledQuantidade() /*|| !itemVenda?.produtoEstProduto || loadingProduto*/}
                        onChange={e => {
                          dispatch(
                            pdvActions.atualizarQuantidadeSuccess({
                              ...itemVenda,
                              itemQuantidade: e.target.value
                            })
                          );
                        }}
                        onBlur={e => changeQuantidade(e.target.value)}
                        value={itemVenda?.itemQuantidade ?? 1}
                        pattern="[0-9]*"
                        onKeyPress={ev => {
                          if (!itemVenda?.produtoEstProduto?.fracionavel && (ev.key === '.' || ev.key === ',')) {
                            ev.preventDefault();
                          }
                          keyDownQuantidade(ev)
                        }}
                      />
                    )}
                  </Mui.Grid>
                  <Mui.Grid item md={3}>
                    <Mui.Typography variant="caption" align="left">
                      Desconto(R$)
                    </Mui.Typography>
                    <Mui.TextField
                      id="itemValorDesconto"
                      variant="outlined"
                      fullWidth
                      value={itemVenda?.itemValorDesconto ?? itemVenda?.itemValorDesconto}
                      onChange={changeDesconto}
                      disabled={isDisabledDesconto()}
                      InputProps={{
                        inputComponent: CurrencyInputCustom,
                        style: { height: 44 }
                      }}
                    />
                  </Mui.Grid>
                  <Mui.Grid item md={2}>
                    <Mui.Typography variant="caption" align="left">
                      Desc.(%)
                    </Mui.Typography>
                    <Mui.TextField
                      id="itemPercentualDesconto"
                      variant="outlined"
                      fullWidth
                      value={itemVenda?.itemPercentualDesconto ?? itemVenda?.itemPercentualDesconto}
                      onChange={changeDescontoPercentual}
                      disabled={isDisabledDesconto()}
                      InputProps={{
                        inputComponent: CurrencyInputCustom,
                        style: { height: 44 }
                      }}
                    />
                  </Mui.Grid>
                  <Mui.Grid item md={2}>
                    <Mui.Typography variant="caption" align="left">
                      Acrésc.(R$)
                    </Mui.Typography>
                    <Mui.TextField
                      variant="outlined"
                      fullWidth
                      value={itemVenda?.itemValorAcrescimo ?? 0}
                      onChange={changeAcrescimo}
                      disabled={!itemVenda?.produtoEstProduto || loadingProduto || bloqueiaDesconto ||
                        (tipoDocumento === TIPO_DOCUMENTO.DOCUMENTOSAIDA ? !rotinaF140DSA : '')
                      }
                      InputProps={{
                        inputComponent: CurrencyInputCustom,
                        style: {
                          height: 44
                        }
                      }}
                    />
                  </Mui.Grid>
                </Mui.Grid>
                <Mui.Divider orientation="horizontal" style={{ margin: '16px 8px' }} />
                <Mui.Grid container item>
                  <Mui.Grid container item md={6} style={{ paddingRight: 16, margin: -1 }}>
                    <Mui.Grid item>
                      <Mui.Typography variant="caption">Armazém</Mui.Typography>
                      {loadingProduto ? (
                        <Skeleton variant="text" />
                      ) : (
                        <Mui.Typography>
                          {itemVenda?.armazens?.length > 0 ? (
                            <Mui.Select
                              variant="outlined"
                              disabled={!itemVenda?.produtoEstProduto || loadingProduto}
                              value={itemVenda?.produtoEstArmazemId ?? null}
                              onChange={e => {
                                console.log('Armazem Selecionado', e);
                                dispatch(
                                  pdvActions.alterarArmazem(
                                    itemVenda?.armazens?.find(x => x.id === e.target.value)
                                  )
                                );
                              }}
                              fullWidth
                              style={{
                                height: 44
                              }}
                            >
                              {itemVenda?.armazens?.map((x, index) => (
                                <Mui.MenuItem key={index} value={x.id}>
                                  {x.descricao}
                                </Mui.MenuItem>
                              ))}
                            </Mui.Select>
                          ) : (
                            <>
                              {itemVenda?.produtoEstArmazem
                                ? `${itemVenda?.produtoEstArmazem?.id}-${itemVenda?.produtoEstArmazem?.descricao}`
                                : 'NÃO SELECIONADO'}
                            </>
                          )}
                        </Mui.Typography>
                      )}
                    </Mui.Grid>
                    <Mui.Grid item></Mui.Grid>
                  </Mui.Grid>
                  <Mui.Divider orientation="vertical" flexItem />
                  <Mui.Grid
                    container
                    item
                    md={6}
                    style={{ paddingLeft: 16, margin: -1 }}
                    alignContent="flex-end"
                  >
                    {loadingProduto ? (
                      <Mui.Grid item md={12}>
                        <Skeleton variant="text" style={{ marginBottom: 8 }} />
                        <Skeleton variant="text" style={{ marginBottom: 8 }} />
                        <Skeleton variant="text" style={{ marginBottom: 8 }} />
                      </Mui.Grid>
                    ) : (
                      <>
                        <Mui.Grid container item justify="space-between">
                          <Mui.Typography variant="caption" align="left">
                            Valor mercadoria:
                          </Mui.Typography>
                          <Mui.Typography align="right">
                            {formatCurrency(Number(itemVenda?.produtoEstProduto?.embalagemMultiplo 
                            ? currency(itemVenda?.itemValorUnitario * itemVenda?.itemQuantidade)
                            : currency(itemVenda?.itemValorUnitario * itemVenda?.itemQuantidade))
                            )}
                          </Mui.Typography>
                        </Mui.Grid>

                        <Mui.Grid container item justify="space-between">
                          <Mui.Typography variant="caption" align="left">
                            Desconto/Acréscimo:
                          </Mui.Typography>
                          <Mui.Typography align="right">
                            {formatCurrency(
                              Number(
                                currency(
                                  itemVenda?.itemValorAcrescimo - itemVenda?.itemValorDesconto
                                )
                              )
                            )}
                          </Mui.Typography>
                        </Mui.Grid>

                        <Mui.Grid container item justify="space-between">
                          <Mui.Typography variant="caption" align="left">
                            Total:
                          </Mui.Typography>
                          <Mui.Typography align="right">
                            <b>
                              {formatCurrency(itemVenda?.produtoEstProduto?.embalagemMultiplo
                              ? currency(
                                itemVenda?.itemValorUnitario * itemVenda?.itemQuantidade -
                                itemVenda?.itemValorDesconto || 0
                              )
                              : currency(
                                itemVenda?.itemValorUnitario * itemVenda?.itemQuantidade -
                                itemVenda?.itemValorDesconto || 0
                              )
                              )}
                            </b>
                          </Mui.Typography>
                        </Mui.Grid>
                      </>
                    )}
                  </Mui.Grid>
                </Mui.Grid>
              </Mui.Grid>
            </Mui.Grid>
          </Mui.Grid>
        </Mui.Grid>
      </Mui.Grid>
      <Mui.Grid container justify="center" alignItems="center" style={{ marginTop: -54 }}>
        <Mui.Fab
          variant="extended"
          style={{
            backgroundColor: 
              !itemVenda?.produtoEstProduto || loadingProduto ? theme.palette.background.default : '#09A848',
            color: !itemVenda?.produtoEstProduto || loadingProduto ? theme.palette.text.disabled : '#FFF',
            boxShadow: theme.shadows[6]
          }}
          disabled={!itemVenda?.produtoEstProduto || loadingProduto}
          onClick={handleAdicionarProduto}
        >
          <MuiIcons.AddCircle />
          <Mui.Typography style={{ marginLeft: 16 }}>
            {!venda?.itens?.find(x => x.uuid === itemVenda?.uuid) ? 'Adicionar produto' : 'Atualizar produto'}
          </Mui.Typography>
        </Mui.Fab>
        <Tooltip title="Condições de Pagamento">
          <Mui.Fab
            size="small"
            aria-label="Condição pagamento"
            disabled={!itemVenda?.produtoEstProduto || loadingProduto}
            style={{
              marginLeft: 8,
              backgroundColor:
                !itemVenda?.produtoEstProduto || loadingProduto
                  ? theme.palette.background.default
                  : '#FFF',
              boxShadow: theme.shadows[6]
            }}
            onClick={() => {
              setShowModalCondicaoPagamento(true);
            }}
          >
            <MuiIcons.LocalAtm />
          </Mui.Fab>
        </Tooltip>
        <Mui.Tooltip title="Mais ações">
          <Mui.Fab
            size="small"
            aria-label="Mais ações"
            disabled={!itemVenda?.produtoEstProduto || loadingProduto}
            style={{
              marginLeft: 8,
              backgroundColor:
                !itemVenda?.produtoEstProduto || loadingProduto
                  ? theme.palette.background.default
                  : '#FFF',
              boxShadow: theme.shadows[6]
            }}
            onClick={handleClick}
          >
            <MuiIcons.MoreVert />
          </Mui.Fab>
        </Mui.Tooltip>

        <Mui.Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <Mui.MenuItem onClick={() => setShowModalProdutoSimilar(true)}>
            Produto similar
          </Mui.MenuItem>
          <Mui.MenuItem onClick={() => setShowModalVendaCasada(true)}>Venda casada</Mui.MenuItem>
          <Mui.MenuItem onClick={() => setShowModalEnderecos(true)}>Endereços</Mui.MenuItem>
        </Mui.Menu>
      </Mui.Grid>

      {showModalCondicaoPagamento && (
        <ModalCondicaoPagamentoProduto
          open={showModalCondicaoPagamento}
          data={{
            produto: itemVenda?.produtoEstProduto,
            precoVenda: itemVenda?.produtoPrecoVenda
          }}
          onClose={() => {
            setShowModalCondicaoPagamento(false);
          }}
        />
      )}

      {showModalProdutoSimilar && (
        <ModalProdutoSimilar
          open={showModalProdutoSimilar}
          data={{ produto: itemVenda?.produtoEstProduto }}
          onClose={({ estProduto, estProdutoGrade, estProdutoImposto, estArmazem, ...produto }) => {
            setShowModalProdutoSimilar(false);

            dispatch(
              pdvActions.selecionarProduto(
                {
                  ...estProduto,
                  estProdutoGrade,
                  estArmazem,
                  estProdutoImposto
                },
                () => {
                  dispatch({
                    type: SET_FOCUS_QUANTIDADE,
                    focus: true
                  });
                }
              )
            );
          }}
        />
      )}

      {showModalVendaCasada && (
        <ModalVendaCasada
          open={showModalVendaCasada}
          data={{ produto: itemVenda?.produtoEstProduto }}
          onClose={() => {
            setShowModalVendaCasada(false);
          }}
        />
      )}

      {showModalEnderecos && (
        <ModalEnderecos
          open={showModalEnderecos}
          data={{ produto: itemVenda?.produtoEstProduto }}
          armazens={itemVenda.armazens}
          onClose={() => {
            setShowModalEnderecos(false);
          }}
        />
      )}
    </>
  );
};

export default ConsultaProduto;
